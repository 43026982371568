@import url('https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i,900|Montserrat:900');

:root {
  --primary-color: #d23669;
  --primary-color-light: rgb(255, 167, 196);
  --pink: rgb(255, 167, 196);
  --quote-blue : rgba(112, 152, 255, 0.9);
  --text-color: rgba(255, 255, 255, 0.88);
  --quote-bg-color:  #282c35;
  --quote-color: var(--quote-blue);

}

:global * {
  box-sizing: border-box;
}

:global html {
  color: var(--text-color);
  font: 100%/1.75 Morriweather, Georgia, serif;
}

:global body {
  font-kerning: normal;
  font-family: 'Merriweather','Georgia',serif;
  font-feature-settings: "kern", "liga", "clig", "calt";
  font-weight: 400;
  margin: 0;
  padding: 0;
  word-wrap: break-word;

  -webkit-font-smoothing: antialiased;

  background-color: #282c35;
  --textNormal: var(--text-color);
  --header: var(--primary-color-light);
  --textTitle: var(--primary-color-light);
  --textLink: var(--pink);
  --hr: hsla(0, 0%, 100%, 0.2);
  /*--inlineCode-bg: hsl(222, 14%, 25%);*/
  --inlineCode-text: #e6e6e6;


}

:global(pre),
:global(code) {
  font-family: Consolas, Menlo, Monaco, source-code-pro, "Courier New", monospace;
  font-size: 0.85rem;
  line-height: 1.5;
}
:global pre {
  border-radius: 10px;
  overflow: auto;
  padding: 1em;
}

:global a {
  color: var(--pink);
}
:global a:hover {
  text-decoration: none;
}

:global p,
:global pre,
:global ul,
:global ol {
  margin-top: 0;
  margin-bottom: 1.75rem;
}