.title {
  font-family: Montserrat, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: italic;
  line-height: 2.5rem;
  margin-top: 0;
  margin-bottom: 2.625rem;
  text-rendering: optimizeLegibility;
  margin-right: 4rem;
}
.title a {
  color: steelblue;
  text-decoration: none;
}

.articlesList,
.articlesList > li {
  list-style: none;
  padding: 0;
  margin: 0 0 2.625rem;;
}

.footer {
  margin-top: 7rem;
  margin-bottom: 0.5rem;
}
